import React, { useMemo, useRef } from 'react';
import { GoogleMap,Marker, useJsApiLoader,DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import './mapStyle.scss'
import { useCallback, useEffect, useState } from 'react';
// import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap,LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
// import L from 'leaflet';
// import marker from '../../Assets/imgs/marker.png'
// import RoutineMachine from "./routingLayer";
import marker1 from '../../Assets/imgs/marker1.svg'
import marker2 from '../../Assets/imgs/marker2.svg'
import scotterIcon from '../../Assets/imgs/scotter.svg'
import branchIcon from '../../Assets/imgs/branch.svg'
import userIcon from '../../Assets/imgs/user.svg'
// import { FullscreenControl } from "react-leaflet-fullscreen";
// import "react-leaflet-fullscreen/dist/styles.css";
import io from 'socket.io-client'
// import socketIOClient from 'socket.io-client';
import { useSelector } from 'react-redux';
import { useGoogleMaps } from '../Common/mapLoader';
import { Button } from '@mui/material';

const libraries = ["places"];


// a custom marker for leaflet

// const iconPerson = new L.Icon({
//     // iconUrl: require('../../Assets/imgs/marker.png'),
//     // iconUrl: 'https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon-2x.png', without require if you want to pass relative pass (link)
//     // iconRetinaUrl: require('../../Assets/imgs/marker.png'),
//     iconSize:     [30, 40], // size of the icon
//     shadowSize:   [50, 64], // size of the shadow
//     // iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
//     shadowAnchor: [40, 62],  // the same for the shadow
//     popupAnchor:  [-3, -76],
//     className : 'customMarker'
// });

// export { iconPerson };

const MapComponent = ({data}) => {
    // console.log(data);
    //Google-Map_api Package

    const [map, setMap] = useState(null)
    const [directions, setDirections] = useState(null);
    const originRef = useRef();
    const destiantionRef = useRef();
    
    const {key} = useSelector(state=>state?.map)
    // console.log(data?.id);
    let id = data?.id
    // const {isLoaded} = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey : key?.data?.GOOGLE_MAP_KEYS,   
    //     libraries 
    // })

    const { isLoaded, loadError } = useGoogleMaps();

    const center = useMemo( ()=>({
        lat : 24.711237,
        lng :  46.684593
    }),[] )


    const onLoad = useCallback(function callback(map) {
        setMap(map)
        }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])
    let count = useRef(0);

    
    const [startMarker,setstartMarker] = useState()
    const [endMarker,setendMarker] = useState()
    const directionsCallback = (response) => {
        
        if (response !== null && count.current < 2) {
            if (response.status === 'OK') {
                count.current += 1;
                setDirections(response);
                // console.log(directonOpt);
                const startLocation = response.routes[0].legs[0].start_location;
                const endLocation = response.routes[0].legs[0].end_location;
    
                // Set the position of your markers
                setstartMarker({ lat: startLocation.lat(), lng: startLocation.lng() });
                setendMarker({ lat: endLocation.lat(), lng: endLocation.lng() });
            } else {
                count.current = 0;
                // console.log('res: ', response);
            }
        }
        
        // console.log(response?.request?.destination?.location?.lat());
    };

    const [originLatLng, setoriginLatLng] = useState(null)
    const [destinationLatLng, setdestinationLatLng] = useState(null)
    const [res, setres] = useState()

    // let value = id

    // console.log(data);
    const [directonOpt, setdirectonOpt] = useState({
        origin: {}, // set the origin coordinates
        destination: { lat: 24.70837, lng: 46.686147 }, // set the destination coordinates

        // origin: {lat: data?.branchCoordinates?.coordinates[0], lng: data?.branchCoordinates?.coordinates[1] }, // set the origin coordinates
        // destination: { lat: data?.customerCoordinates?.coordinates[0], lng: data?.customerCoordinates?.coordinates[1] },
        travelMode: 'DRIVING'
    })

    const handleLocationUpdate = (driverInfo) => {
        // console.log(driverInfo);
        setres(driverInfo)
        if (driverInfo && driverInfo !=='no one has taken that order yet') {
            count.current = 0;
            setdirectonOpt((prevState) => ({
                ...prevState,
                    origin: {
                    lat: driverInfo?.Driver_Location[1] || 0,
                    lng: driverInfo?.Driver_Location[0] || 0,
                },
                destination : {
                    lat: driverInfo?.Order_Destination[1] || 0,
                    lng: driverInfo?.Order_Destination[0] || 0,
                }
            }));
        }
    };

    let token
    try {
        token = localStorage.getItem('tk');
    } catch (error) {
        console.error('Failed to retrieve token:', error);
    }

    const SocketToken = `${token}`;


    const socket = useRef();

    useEffect(() => {
        socket.current = io('https://delivery-develop.takein.sa', {
            auth: {
                token: SocketToken
            },
            
        });

        socket.current.emit('partner-track', id);

        socket.current.on('connection-status', (e) => console.log(e));
        socket.current.on('tracking-info', (driverInfo) => handleLocationUpdate(driverInfo));

        return () => {
            socket.current.disconnect();
        };
    }, [id]);


    useEffect(() => {
        // console.log(res);
        // console.log(directonOpt);
        // console.log(originLatLng?.lat());
    }, [res,directonOpt])

    useEffect(() => {
        if(isLoaded){
            if (typeof window !== 'undefined' && typeof window.google !== 'undefined') {
                
                setoriginLatLng(
                    new window.google.maps.LatLng(
                        directonOpt?.origin?.lat,
                        directonOpt?.origin?.lng
                    )
                );
                setdestinationLatLng(
                    new window.google.maps.LatLng(
                        directonOpt?.destination?.lat,
                        directonOpt?.destination?.lng
                    )
                );

                // new window.google.maps.Marker({
                //     position: directonOpt?.origin,
                //     map,
                //     title: "origin",
                // });
            }
            
        }
    }, [directonOpt]);

    //uncomment this code to make it work if the order status is delivered or pickedup the socket work with delivery only
    const [branchPos, setbranchPos] = useState()
    useEffect(() => {
        if (data?.branchCoordinates?.coordinates && data?.customerCoordinates?.coordinates) {
            setdirectonOpt({
                origin: { lat: data.branchCoordinates.coordinates[1], lng: data.branchCoordinates.coordinates[0] },
                destination: { lat: data.customerCoordinates.coordinates[1], lng: data.customerCoordinates.coordinates[0] },
                travelMode: 'DRIVING'
            });

            setbranchPos({ lat: data.branchCoordinates.coordinates[1], lng: data.branchCoordinates.coordinates[0] })
        }
    }, [data]);

    // console.log(data);
    return ( 
        <>
            {/* <Button onClick={handleSocketTest}>Test</Button> */}
            {isLoaded ? 
                <div className='w-full h-full [&>div]:w-full [&>div]:h-full [&>span]:flex [&>span]:justify-center [&>span]:items-center [&>span]:capitalize [&>span]:font-semibold [&>span]:text-mainColor'>
                    { (data?.orderType === 'DELIVERY' && (data?.orderStatus === 'PICKED_UP' || data?.orderStatus === 'OUT_FOR_DELIVERY' ) ) ?
                        <GoogleMap
                            center={startMarker}
                            zoom={9}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        > 
                            {res && res?.Driver_Location && <Marker
                                position={startMarker} // Set the position of the marker blue
                                icon={{
                                    url: scotterIcon, // Path to the custom marker image
                                    scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
                                }}
                            />}

                            {res && res?.Order_Destination && <Marker
                                position={endMarker} // Set the position of the marker red
                                icon={{
                                    url: userIcon, // Path to the custom marker image
                                    scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
                                }}
                            /> }
                            {/* {console.log('delivery')} */}
                            {directions && (
                                <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} /> 
                            )}
                            <DirectionsService
                                options={directonOpt}
                                callback={directionsCallback}
                            />
                        </GoogleMap> 
                    :
                    
                    data?.orderType !== 'DELIVERY' || (data?.orderType === 'DELIVERY' && (data?.orderStatus !== 'PICKED_UP' || data?.orderStatus !== 'OUT_FOR_DELIVERY' )) ?
                        <GoogleMap
                            center={startMarker}
                            zoom={9}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        > 

                            <Marker
                                position={startMarker} // Set the position of the marker blue
                                icon={{
                                    url: branchIcon, // Path to the custom marker image
                                    scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
                                }}
                            />
                            {/* {console.log('not delivery')} */}

                            <Marker
                                position={endMarker} // Set the position of the marker blue
                                icon={{
                                    url: userIcon, // Path to the custom marker image
                                    scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
                                }}
                            />

                            {directions && (
                                <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} /> 
                            )}
                            <DirectionsService
                                options={directonOpt}
                                callback={directionsCallback}
                            />
                        </GoogleMap> 

                    //     : 
                    // data?.orderStatus === 'PICKED_UP' && data?.orderType !== 'DELIVERY' ?
                    //     <GoogleMap
                    //         center={branchPos}
                    //         zoom={9}
                    //         onLoad={onLoad}
                    //         onUnmount={onUnmount}
                    //     > 

                    //         <Marker
                    //             position={branchPos} // Set the position of the marker blue
                    //         />
                    //     </GoogleMap> 

                        
                        : <span>no location to track</span>
                    }
                </div>
                    : null
            }

        </>
    );
}
 
export default MapComponent;