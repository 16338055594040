import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import previewIcon from '../../../Assets/imgs/previewIcon.svg'
import { Button, Icon, InputAdornment, MenuItem, Switch, TextField, Tooltip, alpha, styled } from "@mui/material";
import { BiEditAlt, BiReset } from "react-icons/bi";
import { IoMdAdd, IoMdHelpCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editMenuItem, getCategories } from "../../../Components/Redux/Slice/Menu/menu";
import { MenuSkeletons } from "../../../Components/Skeleton/skeleton";
import { getMenuItemById } from "../../../Components/Redux/Slice/Menu/menuById";
import BreadCrumb from "../../../Components/BreadCrumb/breadCrumb";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Astrisks from '../../../Components/Common/astrisk'
import { addFile } from "../../../Components/Redux/Slice/Upload/file";
import Overlay from "../../../Components/Common/overlay";
import { useTranslation } from "react-i18next";
import { IoTrashOutline } from "react-icons/io5";
import Options from "./optionsComponent";
import { toast } from "react-toastify";
import PriorityModal from "./priorityModal";
import { v4 as uuidv4 } from 'uuid';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#442B7E',
      '&:hover': {
        backgroundColor: alpha('#442B7E', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#442B7E',
    },
  }));
  

const EditDish = () => {
    const {t,i18n} = useTranslation()
    const MySwal = withReactContent(Swal);
    const {menuItem} = useSelector(state=>state)
    const navigate = useNavigate()
    const location = useLocation()
    const form = useForm({
        defaultValues:{
            options:menuItem?.loading ? [] : menuItem?.menuItem?.data?.options,
            extras:menuItem?.loading ? [] : menuItem?.menuItem?.data?.extras,
        }
    })
    const {register,control,handleSubmit,formState,setValue,getValues,reset,watch,trigger} = form
    const {errors} = formState
    const [preview, setPreview] = useState(location?.state?.data?.image);
    const [img,setimg] = useState(location?.state?.data?.image)
    const [allowImg,setallowImg] = useState(false)
    const [page,setpage] = useState('')
    const dispatch = useDispatch()
    const [textareaCounter, settextareaCounter] = useState(0);
    const [textareaArCounter, settextareaArCounter] = useState(0);

    const {categories} = useSelector(state=>state)
    const {auth} = useSelector(state=>state)
    // console.log(location?.state?.data);
    
    // console.log(auth?.role === 'BRANCH_ADMIN');

    const maxSize = 1024*1024*50
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    const handleImageChange = (e)=> {
        const file = e.target.files[0];

            if(file.size > maxSize){
                MySwal.fire({
                    title: 'Error!',
                    text: localStorage.getItem('i18nextLng') === 'ar' ? 'أقصي مساحه للملف 50 ميجا بايت' : 'File size exceeds 50MB' ,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else if(!acceptedImageTypes.includes(file.type)){
                MySwal.fire({
                    title: 'Error!',
                    text: 'Images must be in type of jpg,jpeg,png only',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                setallowImg(true)
                setimg(file)
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = ()=>{
                setPreview(reader.result);
            }
        }
    }

    const handleImageRest = ()=>{
        setimg(null)
        setPreview(null)
        setallowImg(false)
    }

    // console.log(getValues());

    // adds on

    // const [options, setoptions] = useState([]);
    // const addFieldsHandler = () => {
    //     setoptions(options => [...options, {}]);
    // }
    // const [extras, setextras] = useState([]);
    
    // const addExtrasHandler = () => {
    //     setextras(extras => [...extras, {}]);
    // }

    // useEffect(() => {

    //     if(page === 'edit'){
    //         let options = menuItem?.menuItem?.data?.options || [];
    //         setoptions(options); 
    
    //         let extras = menuItem?.menuItem?.data?.extras || [];
    //         setextras(extras);

    //         options.forEach((option, index) => {
    //             setValue(`options[${index}].name`, option?.name);
    //             setValue(`options[${index}].nameAr`, option?.nameAr);
    //             setValue(`options[${index}].price`, option?.price);
    //             setValue(`options[${index}].calories`, option?.calories);
    //         });
            
    //         extras.forEach((extra, index) => {
    //             setValue(`extras[${index}].name`, extra?.name);
    //             setValue(`extras[${index}].nameAr`, extra?.nameAr);
    //             setValue(`extras[${index}].price`, extra?.price);
    //             setValue(`extras[${index}].calories`, extra?.calories);
    //         });
    //     } else {
    //         setoptions([])
    //         setextras([]);
    //     }

    //     // console.log(options);

    // }, [menuItem?.loading])

    const { fields: optionGroup, append: appendOptionGroup, remove: removeOptionGroup,move : moveOptionGroup } = useFieldArray({
        control,
        name: 'optionGroups',
    });

    const addOptionGroupHandler = () => {
        appendOptionGroup({
            id: uuidv4(),
            optionGroupName : "",
            optionGroupNameAr : "",
            minimumSelections : 1,
            multiselect : false,
            allowSingleOptionMultiselection : false,
            options: [{ name: '', nameAr: '', price: '', calories: '' }],
        });
    };

    const removeOptionGroupHandler = (index) => {
        removeOptionGroup(index);
    };

    

    const { fields: extras, append: appendExtra, remove: removeExtra } = useFieldArray({
        control,
        name: 'extras',
    });
    

    const addExtraHandler = () => {
        appendExtra({ name: '', nameAr: '', calories: '', price: '' });
    };

    const removeExtraHandler = (index) => {
        removeExtra(index);
    };

    useEffect(() => {
        if (!menuItem.loading) {
            reset({
                // options: menuItem?.menuItem?.data?.options,
                optionGroups : menuItem?.menuItem?.data?.optionGroups,
                extras : menuItem?.menuItem?.data?.extras
            });
        }
        }, [menuItem, reset]);



    watch('optionGroup')
    watch('options')
    watch('extras')
    watch()

// console.log(location?.state?.data);
// console.log(menuItem?.menuItem?.data?.optionGroups);
// console.log(optionGroup);

    useEffect(() => {
        dispatch(getCategories())

        if(location?.pathname === '/dashboard/menu/editdish'){
            setpage('edit')
        }

        if(location?.state?.data && page === 'edit'){
            let id = location?.state?.data?.id
            dispatch(getMenuItemById(id))
            
            setValue('name', location?.state?.data?.name); 
            setValue('nameAr', location?.state?.data?.nameAr); 
            // setValue('image', location?.state?.data?.image); 
            setValue('description', location?.state?.data?.description);
            setValue('descriptionAr', location?.state?.data?.descriptionAr);
            setValue('price', location?.state?.data?.price);
            setValue('calories', location?.state?.data?.calories);
            // setValue('image', categories?.menuItem?.data?.image);
            setValue('options', JSON.stringify(menuItem?.menuItem?.data?.options));
            setValue('optionGroup', JSON.stringify(menuItem?.menuItem?.data?.optionGroups))
            setValue('extras', JSON.stringify(menuItem?.menuItem?.data?.extras));
            setValue('categoryId', parseInt(location?.state?.data?.categoryId));

            // options&&options?.forEach((option, index) => {
            //     setValue(`options[${index}].name`, option?.name);
            //     setValue(`options[${index}].price`, option?.price);
            //     setValue(`options[${index}].calories`, option?.calories);
            // });

            // extras?.forEach((extra, index) => {
            //     setValue(`extras[${index}].name`, extra?.name);
            //     setValue(`extras[${index}].price`, extra?.price);
            //     setValue(`extras[${index}].calories`, extra?.calories);
            // });
        }
            // console.log(options);
        // console.log(getValues());
    }, [dispatch, location, page, setValue,getValues])


    // console.log(categories?.loading);

    const submitHandler = async (values)=>{
        // const optionJsonString = JSON.stringify(values.options);
        // const extraJsonString = JSON.stringify(values.extras)

        // const formData = {
        //     ...values, // include all other form data
        //     // options: optionJsonString, // convert options array to JSON string
        //     // extras: extraJsonString // convert extras array to JSON string
        // };
    
        // if (location?.state?.data && page === 'edit') {
        //     const id = location.state.data.id;
        //     dispatch(editMenuItem({ id, formData })).then( (e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/menu') : null );
        // } else{
        //     dispatch(addMenuItem(formData)).then( (e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/menu') : null );
        // }


        const image = new FormData()
        image.append('file',img)
        const id = location.state.data.id;

        const invalidOptionGroupIndex = values?.optionGroups?.findIndex(group => (group.options.length < group.minimumSelections) && !group.allowSingleOptionMultiselection);

        // console.log(formData);
        let ImageResponse = null

        if(img !== null && allowImg === true){
            ImageResponse = await dispatch(addFile(image));
        }


        if (invalidOptionGroupIndex !== -1) {
            // Trigger error
            toast.error(`${t('common.invalidLength',{index:invalidOptionGroupIndex + 1})}`);
        } else {

            try {
                const updatedOptionGroups = values.optionGroups.map((group, index) => ({
                    ...group,
                    order: index + 1,
                }));

                const formData = {
                    ...values, // include all other form data
                    // extras: values.extras || [] , // convert extras array to JSON string
                    optionGroups: updatedOptionGroups, // include updated option groups with orderNumber
                    image : ImageResponse?.payload?.data ? ImageResponse?.payload?.data?.url : img === null ? null : location?.state?.data?.image
                };
                dispatch(editMenuItem({ id, formData })).then( (e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/menu') : null );
            } catch (error) {
                // console.log(error);
            }

            // if(img){
            //     try {
            //         const response = await dispatch(addFile(formData));
            //         if (response.payload?.data) {
            //             const imageUrl = response.payload.data.url;
            //             const formData = {
            //                 ...values, // include all other form data
            //                 // options: values.options || [] , // convert options array to JSON string
            //                 // extras: values.extras || [] , // convert extras array to JSON string
            //                 image : imageUrl
            //             };
        
            //             dispatch(editMenuItem({ id, formData })).then( (e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/menu') : null );
            //         }
            //     } catch (error) {
            //         // console.log(error);
            //     }
            // } else{
            //     try {
            //             const formData = {
            //                 ...values, // include all other form data
            //                 // options: values.options || [] , // convert options array to JSON string
            //                 // extras: values.extras || [] , // convert extras array to JSON string
            //                 image : location?.state?.data?.image
            //             };
        
            //             dispatch(editMenuItem({ id, formData })).then( (e)=>e?.payload?.message === 'SUCCESS' ? navigate('/dashboard/menu') : null );
            //     } catch (error) {
            //         // console.log(error);
            //     }
            // }
        }

        
    }

    useEffect(() => {
        // console.log(textareaCounter);
    }, [textareaCounter,textareaArCounter])

    const paths = [
        {path:'/dashboard/menu',name:'Menu',nameAr:"القائمة"},
        {path:'',name:'edit dish',nameAr:"تعديل الطبق"},
    ]

    const {file} = useSelector(state=>state)

    const [disabledInput,setdisabledInput] = useState(optionGroup.map(() => true))

    const toggleDisabledInputs = (index,checked)=>{
        // const disabled = disabledInput[index]
        setdisabledInput( (prev)=>({
            ...prev,
            [index]:checked
        }) )

        // if(checked){
        //     setValue(`optionGroups[${index}].minimum_selections`,1)
        // }
    }

     // edit priority Modal

    const [openPriority, setopenPriority] = useState(false)

    const handleOpenPriority = ()=>{
        setopenPriority(true)
    }

    const handleClosePriority = ()=>{
        setopenPriority(false)
    }

    const watchedOptionGroup = watch('optionGroups');

    return ( 
        <article className="add-new-dish-wrapper mt-5 mb-10">
            <div className="new-dish-wrapper">
                <section className="new-dish-title-wrapper">
                    <h1 className="text-xl font-semibold text-[#464E5F] font-playfair">{t('menu.add_new_dish.edit_title')}</h1>
                    <div><BreadCrumb paths={paths} seperator='/' /></div>
                </section>

                <section className="new-dish-grid-wrapper mt-5">
                    <form noValidate onSubmit={handleSubmit(submitHandler)} className="flex flex-wrap justify-center lg:justify-start items-start gap-5 lg:gap-3">

                        <section className="menu-item-image-preview-wrapper basis-full md:basis-[45%] lg:basis-[20%] h-[200px] rounded-xl overflow-hidden">

                            <div className="preview-wrapper shadow-md w-full h-full relative p-2 bg-[#f7f5fd] ">
                                <input type="file" id="image" accept="image/*" onChange={handleImageChange} disabled={auth?.role === 'BRANCH_ADMIN'} className="disabled:cursor-not-allowed absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-1 w-full h-full z-40 opacity-0"/>
                                <span onClick={handleImageRest} className="block absolute top-0 right-0 text-2xl bg-[#fff] bg-opacity-30 p-1 text-mainRed cursor-pointer z-[99]"><BiReset /></span>
                                <div className={`absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-full bg-mainYellow ${preview ? 'z-10' : 'z-30'}`}>
                                    <div className="text-center text-white absolute top-0 bottom-0 left-0 right-0 cursor-pointer w-full h-[70%] m-auto flex flex-wrap justify-center items-center [&>*]:basis-full">
                                        <img src={previewIcon} alt="preview" className={`w-[70px] h-[70px]`} />
                                        <p className="capitalize lg:text-sm xl:text-base text-[#fff]">{t('menu.add_new_dish.form.image_label')}</p>
                                        {/* {errors?._?.message ? <p className="capitalize lg:text-sm xl:text-base text-danger">{errors?._?.message}</p> : <p className="capitalize lg:text-sm xl:text-base text-[#fff]">Upload item image</p>} */}
                                    </div>
                                </div>
                                {preview && <img src={preview} alt="Preview" className="w-full h-full absolute top-0 bottom-0 left-0 right-0 z-20" />}
                            </div>
                            
                        </section>

                        <section className="upload-item-info-wrapper basis-full lg:basis-[42%] bg-white p-6 rounded-xl">
                            <div className="upload-form-title mb-5">
                                <h2 className="text-lg text-[#333333] font-semibold">{t('menu.add_new_dish.edit_title')}</h2>
                            </div>
                            
                            <div className="form-input-wrapper flex flex-wrap justify-between [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">

                                <div className="[&>input]:w-full basis-full">
                                    <label>{t('menu.add_new_dish.form.name_en')} <Astrisks /></label>
                                    <input type="text" id="name"
                                        {...register('name',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            // pattern:{
                                            //     value: /^[a-zA-Z+\s#&]+$/,
                                            //     message : localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح فقط بالاحرف الانجليزية والمسافات والعلامات (+, &, #)' : 'Only letters, (+, &, #) or spaces allowed'
                                            // },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 30,
                                                message : t('common.max_length',{length : '30'})
                                            }
                                        })}
                                        disabled={auth?.role === 'BRANCH_ADMIN'}
                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.name_en_helper')}</p>}
                                </div>

                                <div className="[&>input]:w-full basis-full">
                                    <label>{t('menu.add_new_dish.form.name_ar')} <Astrisks /></label>
                                    <input type="text" id="nameAr"
                                        {...register('nameAr',{
                                            required:{
                                                value : true,
                                                message : t('common.required')
                                            },
                                            // pattern: {
                                            //     value: /^[\u0621-\u064A\s&0-9]+$/,
                                            //     message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & والارقام فقط' : 'Only Arabic letters, spaces, & sign and numbers are allowed'
                                            // },
                                            minLength : {
                                                value : 3,
                                                message : t('common.min_length',{length : '3'})
                                            },
                                            maxLength : {
                                                value : 30,
                                                message : t('common.max_length',{length : '30'})
                                            }
                                        })}
                                        disabled={auth?.role === 'BRANCH_ADMIN'}
                                        minLength={3}
                                        maxLength={50}
                                    />

                                    {errors?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.name_ar_helper')}</p>}
                                </div>

                                <div className="basis-full flex flex-wrap lg:flex-nowrap [&>div>p]:mt-1 [&>div]:basis-full md:[&>div]:basis-[48%] gap-5 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor">
                                    <div className="[&>div]:bg-[#f3f6f9]">
                                        <input type="hidden" {...register('categoryId')} value={parseInt(location?.state?.data?.categoryId)} />
                                        <label>{t('menu.add_new_dish.form.item_category')}</label>
                                        <TextField
                                            {...register('categoryId',{
                                                // required:{
                                                //     value:true,
                                                //     message: t('common.required')
                                                // }
                                            })}
                                            className="w-full"
                                            id="categoryId"
                                            select
                                            disabled
                                            // label="Select"
                                            defaultValue={location?.state?.data?.categoryId}
                                            // helperText="Please select item category"
                                            sx={{'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input' : {backgroundColor : '#f3f6f9'}}}
                                            >
                                            {categories?.categories?.data?.map( (item)=>(
                                                <MenuItem value={item?.id} key={item?.id}>
                                                    {item?.name}
                                                </MenuItem>
                                            ) )}
                                        </TextField>
                                        {errors?.categoryId?.message ? <p className="capitalize text-sm text-danger">{errors?.categoryId?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.item_category_helper')}</p>}
                                    </div>

                                    <div className="[&>div]:bg-[#f3f6f9]">
                                        <label>{t('menu.add_new_dish.form.price')}</label>

                                        <TextField
                                            type="number"
                                            id="price"
                                            {...register('price',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                max : {
                                                    value : 2000,
                                                    message : t('common.max_value',{value : '2000'})
                                                },
                                                valueAsNumber : true
                                            })}
                                            // label="With normal TextField"
                                            // sx={{ m: 1, width: '25ch', }}
                                            sx={{'& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input' : {padding:'13px 14px'},'& fieldset':{border : 0},'& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root' : {backgroundColor : '#f3f6f9'}}}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">SAR</InputAdornment>,
                                                
                                            }}
                                            inputProps={{
                                                min : 0
                                            }}
                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                        />

                                        {errors?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.price_helper')}</p>}
                                    </div>
                                </div>

                                <div className="basis-full [&>input]:w-full">
                                    <label>{t('menu.add_new_dish.form.calories')} </label>
                                    <input type="number" id="calories"
                                        {...register('calories',{
                                            // required:{
                                            //     value : true,
                                            //     message : t('common.required')
                                            // },
                                            min : {
                                                value : 0,
                                                message : t('common.max_value',{value : '0'})
                                            },
                                            max : {
                                                value : 50000,
                                                message : t('common.max_value',{value : '50000'})
                                            },
                                            valueAsNumber : true
                                        })} 
                                        placeholder="Optional: 50 KCL"
                                        min={0}
                                        disabled={auth?.role === 'BRANCH_ADMIN'}
                                    />
                                    {errors?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.calories_helper')}</p>}
                                </div>

                                <div className="basis-full mt-3">
                                        <label>{t('menu.add_new_dish.form.desc_en')} <Astrisks /></label>
                                        <div className="relative">
                                            <textarea onKeyUp={(e)=>settextareaCounter(e.target.value.length)} name="description" id="description" className="w-full min-h-[100px] h-auto p-3 bg-[#f3f6f9] resize-none rounded-md"
                                                {...register('description',{
                                                    required:{
                                                        value : true,
                                                        message : t('common.required')
                                                    },
                                                    // minLength : {
                                                    //     value : 10,
                                                    //     message : t('common.min_length',{length : '10'})
                                                    // },
                                                    // maxLength : {
                                                    //     value : 500,
                                                    //     message : t('common.max_length',{length : '500'})
                                                    // },
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                        message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وجميع الرموز الخاصة' : 'Only English letters, numbers, and all special characters are allowed'
                                                    },
                                                })}

                                                minLength={10}
                                                maxLength={500}
                                                disabled={auth?.role === 'BRANCH_ADMIN'}
                                            >
                                            </textarea>
                                            <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaCounter}</p>
                                        </div>

                                        {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.desc_en_helper')}</p>}
                                </div>

                                <div className="basis-full mt-3">
                                    <label>{t('menu.add_new_dish.form.desc_ar')} <Astrisks /></label>
                                    <div className="relative">
                                        <textarea onKeyUp={(e)=>settextareaArCounter(e.target.value.length)} name="descriptionAr" id="descriptionAr" className="w-full min-h-[100px] h-auto p-3 bg-[#f3f6f9] resize-none rounded-md"
                                            {...register('descriptionAr',{
                                                required:{
                                                    value : true,
                                                    message : t('common.required')
                                                },
                                                // minLength : {
                                                //     value : 10,
                                                //     message : t('common.min_length',{length : '10'})
                                                // },
                                                // maxLength : {
                                                //     value : 500,
                                                //     message : t('common.max_length',{length : '500'})
                                                // },
                                                pattern: {
                                                    value: /^[\u0621-\u064A0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                    message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالأحرف العربية والأرقام وجميع الرموز الخاصة' : 'Only Arabic letters, numbers, and all special characters are allowed'
                                                }
                                            })}
                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                            minLength={10}
                                            maxLength={500}
                                        >
                                        </textarea>
                                        <p className="absolute bottom-2 right-2 text-[12px] text-[#0000005a]">{500 - textareaArCounter}</p>
                                    </div>

                                    {errors?.descriptionAr?.message ? <p className="capitalize text-sm text-danger">{errors?.descriptionAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.desc_ar_helper')}</p>}
                                </div>

                            </div>
                        </section>

                        <section className="add-ads-on-wrapper basis-full lg:basis-[34%]">
                            <div className="flex flex-col ">
                                <div>
                                    <div className="add-ads-on bg-white px-6 py-8 rounded-xl max-h-[450px] overflow-hidden overflow-y-auto">

                                        <div className="flex justify-between items-start">
                                            <h2 className={`flex items-center text-[#333] font-semibold mb-5 ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`} >{t('menu.add_new_dish.form.options.title')} <span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>

                                            <Tooltip
                                                placement="top"
                                                className="text-lg capitalize"
                                                title={t('menu.add_new_dish.form.options.tooltip')}
                                            >
                                                <Icon className="w-auto h-auto">
                                                    <IoMdHelpCircle className="text-mainColor text-2xl" />
                                                </Icon>
                                            </Tooltip>
                                        </div>

                                        {menuItem?.loading && menuItem?.menuItem !=null ? <MenuSkeletons /> :
                                            
                                            // options&&options?.map((field, index) => (
                                            //     <div key={field.id} className="w-full flex flex-wrap justify-between items-center border-b pb-2 mt-1 mb-4 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
    
                                            //         <div className="[&>input]:w-full  basis-full">
                                            //             <label htmlFor={`addName${index}`}>{t('menu.add_new_dish.form.options.form.name_en')}</label>
                                            //             <input
                                            //                 type="text"
                                            //                 id={`addName${index}`}
                                            //                 {...register(`options[${index}].name`, {
                                            //                     // required: {
                                            //                     //     value: true,
                                            //                     //     message: t('common.required')
                                            //                     // },
                                            //                     // pattern: {
                                            //                     //     value: /^[a-zA-Z0-9&\s]+$/,
                                            //                     //     message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                            //                     // },
                                            //                     // minLength : {
                                            //                     //     value : 3,
                                            //                     //     message : t('common.min_length',{length : '3'})
                                            //                     // },
                                            //                     // maxLength : {
                                            //                     //     value : 50,
                                            //                     //     message : t('common.max_length',{length : '50'})
                                            //                     // },
                                            //                 })}
                                            //                 // minLength={3}
                                            //                 // maxLength={50}
                                            //                 defaultValue={field.name}
                                            //                 disabled={auth?.role === 'BRANCH_ADMIN'}
                                            //             />
                                            //             {errors?.options?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.name_en_helper')}</p>}
                                            //         </div>
    
                                            //         <div className="[&>input]:w-full  basis-full">
                                            //             <label htmlFor={`addName${index}`}>{t('menu.add_new_dish.form.options.form.name_ar')}</label>
                                            //             <input
                                            //                 type="text"
                                            //                 id={`addName${index}`}
                                            //                 {...register(`options[${index}].nameAr`, {
                                            //                     // required: {
                                            //                     //     value: true,
                                            //                     //     message: t('common.required')
                                            //                     // },
                                            //                     // pattern: {
                                            //                     //     value: /^[\u0621-\u064A\s&]+$/,
                                            //                     //     message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & فقط' : 'Only Arabic letters, spaces, & sign are allowed'
                                            //                     // },
                                            //                     // minLength : {
                                            //                     //     value : 3,
                                            //                     //     message : t('common.min_length',{length : '3'})
                                            //                     // },
                                            //                     // maxLength : {
                                            //                     //     value : 50,
                                            //                     //     message : t('common.max_length',{length : '50'})
                                            //                     // },
                                            //                 })}
                                            //                 // minLength={3}
                                            //                 // maxLength={50}
                                            //                 defaultValue={field.nameAr}
                                            //                 disabled={auth?.role === 'BRANCH_ADMIN'}
                                            //             />
                                            //             {errors?.options?.[index]?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.name_ar_helper')}</p>}
                                            //         </div>
    
                                            //         <div className="[&>input]:w-full  basis-[48%]">
                                            //             <label htmlFor={`addPrice${index}`}>{t('menu.add_new_dish.form.options.form.price')}</label>
                                            //             <input
                                            //                 type="number"
                                            //                 id={`addPrice${index}`}
                                            //                 {...register(`options[${index}].price`, {
                                            //                     // required: {
                                            //                     //     value: true,
                                            //                     //     message: t('common.required')
                                            //                     // },
                                            //                     // min : {
                                            //                     //     value : 0,
                                            //                     //     message : t('common.min_value',{value : '0'})
                                            //                     // },
                                            //                     // max : {
                                            //                     //     value : 2000,
                                            //                     //     message : t('common.max_value',{value : '2000'})
                                            //                     // }
                                            //                 })}
                                            //                 // min={0}
                                            //                 defaultValue={field.price}
                                            //                 disabled={auth?.role === 'BRANCH_ADMIN'}
                                            //             />
                                            //             {errors?.options?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.price_helper')}</p>}
                                            //         </div>
    
                                            //         <div className="[&>input]:w-full  basis-[48%]">
                                            //             <label htmlFor={`addCalories${index}`}>{t('menu.add_new_dish.form.options.form.calories')}</label>
                                            //             <input
                                            //                 type="number"
                                            //                 id={`addCalories${index}`}
                                            //                 {...register(`options[${index}].calories`, {
                                            //                     // required: {
                                            //                     //     value: true,
                                            //                     //     message: t('common.required')
                                            //                     // },
                                            //                     // min : {
                                            //                     //     value : 0,
                                            //                     //     message : t('common.min_value',{value : '0'})
                                            //                     // },
                                            //                     // max : {
                                            //                     //     value : 50000,
                                            //                     //     message : t('common.max_value',{value : '50000'})
                                            //                     // }
                                            //                 })}
                                            //                 // min={0}
                                            //                 defaultValue={field.calories}
                                            //                 disabled={auth?.role === 'BRANCH_ADMIN'}
                                            //             />
                                            //             {errors?.options?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.options?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.options.form.calories_helper')}</p>}
                                            //         </div>
    
                                            //         <div className="basis-full mt-3">
                                            //             {auth?.role === 'BRANCH_ADMIN' ? null : 
                                            //                 <Button onClick={() => removeOptionHandler(index)} className="bg-mainRed text-white p-1 min-w-[35px] capitalize w-full">
                                            //                     {t('menu.add_new_dish.form.options.form.remove_btn')}
                                            //                 </Button>
                                            //             }
                                            //         </div>
                                            //     </div>
                                            // ))
                                            optionGroup&&optionGroup?.map((group, index) => (
                                                
                                                <div key={group.id}>
                                                    <div className="[&>input]:w-full  basis-full">
                                                        <div className="flex justify-between items-center capitalize mb-3">
                                                            <p className="text-sm font-semibold">{t('menu.add_new_dish.form.options.option_group')} #{(index + 1)}</p>
                                                            <p className="text-mainRed cursor-pointer text-xl" onClick={() => removeOptionGroupHandler(index)}><IoTrashOutline className="w-full" /></p>
                                                        </div>
    
                                                        <div className="option-group-form-wrapper w-full flex flex-wrap justify-between items-center border-b pb-2 mt-6 mb-4 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(69,69,69)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-medium [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                                            <div className="[&>input]:w-full basis-[48.5%]">
                                                                <label htmlFor={`group${index}`}>{t('menu.add_new_dish.form.options.group_form.group_name_en')} </label>
                                                                <input
                                                                    type="text"
                                                                    id={`group${index}`}
                                                                    className="text-left"
                                                                    {...register(`optionGroups[${index}].optionGroupName`, {
                                                                        required: {
                                                                            value: true,
                                                                            message: t('common.required')
                                                                        },
                                                                        pattern: {
                                                                            value: /^[a-zA-Z0-9&\s]+$/,
                                                                            message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                                        },
                                                                        // minLength : {
                                                                        //     value : 3,
                                                                        //     message : t('common.min_length',{length : '3'})
                                                                        // },
                                                                        // maxLength : {
                                                                        //     value : 50,
                                                                        //     message : t('common.max_length',{length : '50'})
                                                                        // },
                                                                    })}
                                                                    // minLength={3}
                                                                    // maxLength={50}
                                                                    defaultValue={group?.optionGroupName}
                                                                />
                                                                {errors?.optionGroups?.[index]?.optionGroupName?.message ? <p className="capitalize text-xs text-danger">{errors?.optionGroups?.[index]?.optionGroupName?.message}</p> : <p className="capitalize text-xs text-[#B5B5C3]">{t('menu.add_new_dish.form.options.group_form.group_name_en_helper')}</p>}
                                                            </div>
    
                                                            <div className="[&>input]:w-full basis-[48.5%]">
                                                                <label htmlFor={`group${index}`}>{t('menu.add_new_dish.form.options.group_form.group_name_ar')} </label>
                                                                <input
                                                                    type="text"
                                                                    id={`group${index}`}
                                                                    className="text-right"
                                                                    {...register(`optionGroups[${index}].optionGroupNameAr`, {
                                                                        required: {
                                                                            value: true,
                                                                            message: t('common.required')
                                                                        },
                                                                        pattern: {
                                                                            value: /^[\u0621-\u064A\s&]+$/,
                                                                            message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف العربية فقط' : 'Only Arabic letters are allowed'
                                                                        },
                                                                        // minLength : {
                                                                        //     value : 3,
                                                                        //     message : t('common.min_length',{length : '3'})
                                                                        // },
                                                                        // maxLength : {
                                                                        //     value : 50,
                                                                        //     message : t('common.max_length',{length : '50'})
                                                                        // },
                                                                    })}
                                                                    // minLength={3}
                                                                    // maxLength={50}
                                                                    defaultValue={group?.optionGroupNameAr}
                                                                />
                                                                {errors?.optionGroups?.[index]?.optionGroupNameAr?.message ? <p className="capitalize text-xs text-danger">{errors?.optionGroups?.[index]?.optionGroupNameAr?.message}</p> : <p className="capitalize text-xs text-[#B5B5C3]">{t('menu.add_new_dish.form.options.group_form.group_name_ar_helper')}</p>}
                                                            </div>
    
                                                            <div className="w-full basis-full flex justify-between items-center mt-3">
                                                                <Controller
                                                                    control={control}
                                                                    name={`optionGroups[${index}].multiselect`}
                                                                    defaultValue={group?.multiselect}
                                                                    render={({ field }) => (
                                                                        <div className="w-full basis-full flex justify-between items-center mt-3">
                                                                            <p className="text-xs font-medium">{t('menu.add_new_dish.form.options.group_form.multi_select')}</p>
                                                                            <CustomSwitch name="multiSelection"  
                                                                                defaultChecked={field.value} 
                                                                                onChange={(e)=>{
                                                                                    field.onChange(e.target.checked)
                                                                                    // handleSwitchChange(e)
                                                                                    toggleDisabledInputs(index,!e.target.checked)
                                                                                    
                                                                                    if(!e.target.checked){
                                                                                        setValue(`optionGroups[${index}].minimumSelections`,1)
                                                                                        setValue(`optionGroups[${index}].allowSingleOptionMultiselection`,false)
                                                                                    } else if(e.target.checked){
                                                                                        trigger("minimumSelections")
                                                                                    }
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
    
                                                            <div className="[&>input]:w-full basis-full">
                                                                <label htmlFor={`group${index}`}>{t('menu.add_new_dish.form.options.group_form.min_selection')} </label>
                                                                <input
                                                                    type="text"
                                                                    defaultValue={group?.minimumSelections}
                                                                    id={`group${index}`}
                                                                    className="disabled:opacity-50 disabled:bg-gray-400 disabled:cursor-not-allowed"
                                                                    {...register(`optionGroups[${index}].minimumSelections`, {
                                                                        required: {
                                                                            value: getValues(`optionGroups[${index}].multiselect`) ? true : false,
                                                                            message: t('common.required')
                                                                        },
                                                                        // pattern: {
                                                                        //     value: /^[a-zA-Z0-9&\s]+$/,
                                                                        //     message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                                        // },
                                                                        // minLength : {
                                                                        //     value : 3,
                                                                        //     message : t('common.min_length',{length : '3'})
                                                                        // },
                                                                        // maxLength : {
                                                                        //     value : 50,
                                                                        //     message : t('common.max_length',{length : '50'})
                                                                        // },
                                                                        min:{
                                                                            value:1,
                                                                            message : t('menu.add_new_dish.form.options.group_form.min_warning',{value : '1'})
                                                                        }
                                                                    })}
                                                                    min={1}
                                                                    // minLength={3}
                                                                    // maxLength={50}
                                                                    disabled={(disabledInput&&disabledInput[index]) || !getValues(`optionGroups[${index}].multiselect`)}
                                                                />
                                                                {errors?.optionGroups?.[index]?.minimumSelections?.message ? <p className="capitalize text-xs text-danger">{errors?.optionGroups?.[index]?.minimumSelections?.message}</p> : <p className="capitalize text-xs text-[#B5B5C3]">{t('menu.add_new_dish.form.options.group_form.min_selection_helper')}</p>}
                                                            </div>
    
                                                            {getValues(`optionGroups[${index}].multiselect`) === true ? 
                                                                <div className="w-full basis-full flex justify-between items-center">
                                                                    <Controller
                                                                        control={control}
                                                                        name={`optionGroups[${index}].allowSingleOptionMultiselection`}
                                                                        defaultValue={false}
                                                                        render={({ field }) => (
                                                                            <div className="w-full basis-full flex justify-between items-center mt-3">
                                                                                <p className="text-xs font-medium">{t('menu.add_new_dish.form.options.group_form.single_option')}</p>
                                                                                <CustomSwitch name="singleSelection" defaultChecked={field.value} onChange={(e)=>{
                                                                                    field.onChange(e.target.checked)
                                                                                    // handleSwitchChange(e)
                                                                                }} />
                                                                            </div>
                                                                        )}
                                                                    />  
                                                                </div> : null }
    
                                                            <div className="options-wrapper mt-3 capitalize basis-full">
                                                                <Options control={control} register={register} parentIndex={index} errors={errors} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>

                                    {!menuItem?.loading ? 
                                        <div className="mt-4 flex items-center justify-between">
                                            {/* <ListItemButton onClick={addFieldsHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                                <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">add more</span>
                                            </ListItemButton> */}
                                            { auth?.role === 'BRANCH_ADMIN' ? null :
                                                // <Button onClick={addOptionHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                                //     <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">{t('menu.add_new_dish.form.options.form.add_btn')}</span>
                                                // </Button>
                                                <Button onClick={addOptionGroupHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                                    <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">{t('menu.add_new_dish.form.options.group_form.add_btn')}</span>
                                                </Button>
                                                
                                            }

                                            {optionGroup?.length > 1 ?
                                                <div>
                                                    <Button onClick={handleOpenPriority} className="bg-transparent border border-solid border-mainYellow text-mainYellow min-w-[130px] capitalize ">{t('common.editPiriorty')}</Button>
                                                </div>

                                                : 

                                                null
                                            }
                                            <PriorityModal open={openPriority} close={handleClosePriority} data={watchedOptionGroup} t={t} move={moveOptionGroup} />
                                        </div> 
                                    : null}
                                </div>

                                <div>
                                    <div className="add-extras bg-white px-6 py-9 rounded-xl mt-9 max-h-[450px] overflow-hidden overflow-y-auto">
                                        <div className="flex justify-between items-start">
                                            <h2 className={`flex ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'} items-center text-[#333] font-semibold mb-5`}>{t('menu.add_new_dish.form.extras.title')}<span className="ml-2 text-2xl text-mainGreen"><BiEditAlt /></span></h2>

                                            <Tooltip
                                                placement="top"
                                                className="text-lg capitalize"
                                                title={t('menu.add_new_dish.form.extras.tooltip')}
                                            >
                                                <Icon className="w-auto h-auto">
                                                    <IoMdHelpCircle className="text-mainColor text-2xl" />
                                                </Icon>
                                            </Tooltip>
                                        </div>
                                        {menuItem?.loading && menuItem?.menuItem !=null ? <MenuSkeletons /> :
                                            // extras&&extras?.map((_, index) => (
                                            //     <div key={index} className="w-full flex flex-wrap justify-between items-center border-b pb-2  mb-4 mt-1 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                            //         <div className="basis-full [&>input]:w-full">
                                            //             <label>English Name</label>
                                            //             <input type="text" id={`addName${index}`}
                                            //                 {...register(`extras[${index}].name`, {
                                            //                     required: {
                                            //                     value: true,
                                            //                     message: t('common.required')
                                            //                     },
                                            //                     minLength : {
                                            //                         value : 3,
                                            //                         message : 'min length is 3 characters'
                                            //                     },
                                            //                     maxLength : {
                                            //                         value : 50,
                                            //                         message : 'max length is 50 characters'
                                            //                     }
                                            //                 })}
                                            //                 minLength={3}
                                            //                 maxLength={50}
                                            //                 defaultValue={extras[index].name}
                                            //             />
                                            //             {errors?.extras?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras english name</p>}
                                            //         </div>

                                            //         <div className="basis-full [&>input]:w-full">
                                            //             <label>arabic Name</label>
                                            //             <input type="text" id={`addName${index}`}
                                            //                 {...register(`extras[${index}].nameAr`, {
                                            //                     required: {
                                            //                     value: true,
                                            //                     message: t('common.required')
                                            //                     },
                                            //                     minLength : {
                                            //                         value : 3,
                                            //                         message : 'min length is 3 characters'
                                            //                     },
                                            //                     maxLength : {
                                            //                         value : 50,
                                            //                         message : 'max length is 50 characters'
                                            //                     }
                                            //                 })}
                                            //                 minLength={3}
                                            //                 maxLength={50}
                                            //                 defaultValue={extras[index].nameAr}
                                            //             />
                                            //             {errors?.extras?.[index]?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras arabic name</p>}
                                            //         </div>

                                            //         <div className="basis-[48%] [&>input]:w-full">
                                            //             <label>Price</label>
                                            //             <input type="text" id={`addPrice${index}`}
                                            //                 {...register(`extras[${index}].price`, {
                                            //                     required: {
                                            //                         value: true,
                                            //                         message: t('common.required')
                                            //                     },
                                            //                     max : {
                                            //                         value : 2000,
                                            //                         message : 'max value is 2000 SAR'
                                            //                     }
                                            //                 })}
                                            //                 defaultValue={extras[index].price}
                                            //             />
                                            //             {errors?.extras?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras price</p>}
                                            //         </div>

                                            //         <div className="basis-[48%] [&>input]:w-full">
                                            //             <label>Calories</label>
                                            //             <input type="text" id={`addCalories${index}`}
                                            //                 {...register(`extras[${index}].calories`, {
                                            //                     required: {
                                            //                         value: true,
                                            //                         message: t('common.required')
                                            //                     },
                                            //                     min : {
                                            //                         value : 1,
                                            //                         message : 'Calories Value must be greater than 0'
                                            //                     },
                                            //                     max : {
                                            //                         value : 50000,
                                            //                         message : 'Calories Max Value is 50000'
                                            //                     }
                                            //                 })}
                                            //                 defaultValue={extras[index].calories}
                                            //             />
                                            //             {errors?.extras?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">extras calories</p>}
                                            //         </div>
                                            //     </div>
                                            // ))

                                            extras&&extras?.map((field, index) => (
                                                <div key={field?.id} className="w-full flex flex-wrap justify-between items-center border-b pb-2 mt-1 mb-4 [&>div]:mb-2 [&>div>label]:capitalize [&>div>label]:text-[rgb(51,51,51)] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-1 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:p-2 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                                    <div className="[&>input]:w-full basis-full">
                                                        <label>{t('menu.add_new_dish.form.extras.form.name_en')}</label>
                                                        <input type="text" id={`addName${index}`}
                                                            {...register(`extras[${index}].name`, {
                                                                // required: {
                                                                //     value: true,
                                                                //     message: t('common.required')
                                                                // },
                                                                // pattern: {
                                                                //     value: /^[a-zA-Z0-9&\s]+$/,
                                                                //     message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وعلامه & فقط' : 'Only English letters, numbers, and & are allowed'
                                                                // },
                                                                // minLength : {
                                                                //     value : 3,
                                                                //     message : t('common.min_length',{length : '3'})
                                                                // },
                                                                // maxLength : {
                                                                //     value : 50,
                                                                //     message : t('common.max_length',{length : '50'})
                                                                // },
                                                            })}
                                                            // minLength={3}
                                                            // maxLength={50}
                                                            defaultValue={field.name}
                                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                                        />
                                                        {errors?.extras?.[index]?.name?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.name_en_helper')}</p>}
                                                    </div>
    
                                                    <div className="[&>input]:w-full basis-full">
                                                        <label>{t('menu.add_new_dish.form.extras.form.name_ar')}</label>
                                                        <input type="text" id={`addName${index}`}
                                                            {...register(`extras[${index}].nameAr`, {
                                                                // required: {
                                                                //     value: true,
                                                                //     message: t('common.required')
                                                                // },
                                                                // pattern: {
                                                                //     value: /^[\u0621-\u064A\s&]+$/,
                                                                //     message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف العربية والمسافات وعلامه & فقط' : 'Only Arabic letters, spaces, & sign are allowed'
                                                                // },
                                                                // minLength : {
                                                                //     value : 3,
                                                                //     message : t('common.min_length',{length : '3'})
                                                                // },
                                                                // maxLength : {
                                                                //     value : 50,
                                                                //     message : t('common.max_length',{length : '50'})
                                                                // },
                                                            })}
                                                            // minLength={3}
                                                            // maxLength={50}
                                                            defaultValue={field.nameAr}
                                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                                        />
                                                        {errors?.extras?.[index]?.nameAr?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.name_ar_helper')}</p>}
                                                    </div>
    
                                                    <div className="[&>input]:w-full basis-[48%]">
                                                        <label>{t('menu.add_new_dish.form.extras.form.price')}</label>
                                                        <input type="number" id={`addPrice${index}`}
                                                            {...register(`extras[${index}].price`, {
                                                                // required: {
                                                                //     value: true,
                                                                //     message: t('common.required')
                                                                // },
                                                                // min : {
                                                                //     value : 0,
                                                                //     message : t('common.min_value',{value : '0'})
                                                                // },
                                                                // max : {
                                                                //     value : 2000,
                                                                //     message : t('common.max_value',{value : '2000'})
                                                                // }
                                                            })}
                                                            min={0}
                                                            defaultValue={field.price}
                                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                                        />
                                                        {errors?.extras?.[index]?.price?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.price?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.price_helper')}</p>}
                                                    </div>
    
                                                    <div className="[&>input]:w-full basis-[48%]">
                                                        <label>{t('menu.add_new_dish.form.extras.form.calories')}</label>
                                                        <input type="number" id={`addCalories${index}`}
                                                            {...register(`extras[${index}].calories`, {
                                                                // required: {
                                                                //     value: true,
                                                                //     message: t('common.required')
                                                                // },
                                                                // min : {
                                                                //     value : 0,
                                                                //     message : t('common.min_value',{value : '0'})
                                                                // },
                                                                // max : {
                                                                //     value : 50000,
                                                                //     message : t('common.max_value',{value : '50000'})
                                                                // }
                                                            })}
                                                            min={0}
                                                            defaultValue={field.calories}
                                                            disabled={auth?.role === 'BRANCH_ADMIN'}
                                                        />
                                                        {errors?.extras?.[index]?.calories?.message ? <p className="capitalize text-sm text-danger">{errors?.extras?.[index]?.calories?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.add_new_dish.form.extras.form.calories_helper')}</p>}
                                                    </div>
    
                                                    <div className="basis-full">
                                                        {auth?.role === 'BRANCH_ADMIN' ? null :
                                                            <Button onClick={() => removeExtraHandler(index)} className="bg-mainRed text-white p-1 min-w-[35px] capitalize w-full">
                                                                {t('menu.add_new_dish.form.extras.form.remove_btn')}
                                                            </Button>
                                                        }
                                                        
                                                    </div>
    
                                                </div>
                                            ))}
                                    </div>
                                </div>

                                {!menuItem?.loading ? 
                                    <div className="mt-4">
                                        {/* <ListItemButton onClick={addExtrasHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                            <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">add more</span>
                                        </ListItemButton> */}
                                        {auth?.role === 'BRANCH_ADMIN' ? null :
                                            <Button onClick={addExtraHandler} className="capitalize w-fit text-mainGreen bg-[#5cac7d1a] rounded-md">
                                                <span className="mr-3 text-xl"><IoMdAdd /></span> <span className="text-sm font-[500] py-1">{t('menu.add_new_dish.form.extras.form.add_btn')}</span>
                                            </Button>
                                        }
                                        
                                    </div> 
                                : null}

                            </div>
                        </section>

                        {auth?.role === 'BRANCH_ADMIN' ? null :
                            <section className="adds-on-submit-btn basis-full flex justify-end mt-4">
                                <Button type="submit" disabled={categories?.loading} className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen text-white text-base font-bold capitalize w-[200px] py-3 rounded-lg">{t('menu.add_new_dish.form.upload_item')}</Button>
                            </section>
                        }
                        

                    </form>
                </section>
            </div>
            {categories?.loading || file?.loading ? <Overlay /> : null}
        </article>
    );
}

export default EditDish;